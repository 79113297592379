import React, { FC, useCallback, useMemo, useState } from 'react';

import GreenCheckbox from '../../../GreenCheckbox';
import TagsList from '../../../tags-list';

import './index.css';

import { useClassnames } from '@/hooks/use-classnames';
import { ITagsList } from '@/types/strapi/vacancies';

import { IFilterValue } from '../../types';


interface IFilterSectionProps {
    title: string,
    items: Array<IFilterValue>,
    selectedItems: Record<string, IFilterValue>,
    onClickMethod: (e: React.MouseEvent) => void,
    getItemValue?: (item: IFilterValue) => string
}

const FilterSection: React.FC<IFilterSectionProps> = ({
    title,
    items,
    selectedItems,
    onClickMethod,
    getItemValue
}) => {
    const cn = useClassnames();

    return (
        <div className="directions__filters-item">
            <h3 className="directions__filters-title">{title}</h3>
            <ul className="directions__points">
                {items.map((item, index) => {
                    const id = item.id;
                    const value = getItemValue?.(item);
                    const isActive = Boolean(selectedItems[id]);

                    return (
                        <li
                            key={index}
                            className={cn('directions__points-item', {
                                'directions__points-item_active': isActive
                            })}
                            data-id={id}
                            data-value={value}
                            onClick={onClickMethod}
                        >
                            <GreenCheckbox
                                small={true}
                                isChecked={isActive}
                            />
                            {item.text}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};


interface IProps {
    tags: {
        tags: ITagsList['tags'],
        selectedItems: Record<string, IFilterValue>,
        onClickTag: (e: React.MouseEvent) => void
    },
    filters: {
        directions: {
            list: Array<IFilterValue>,
            selectedItems: Record<string, IFilterValue>,
            onClickMethod: (e: React.MouseEvent) => void
        },
        cities: {
            list: Array<IFilterValue>,
            selectedItems: Record<string, IFilterValue>,
            onClickMethod: (e: React.MouseEvent) => void
        },
        jobTypes: {
            list: Array<IFilterValue>,
            selectedItems: Record<string, IFilterValue>,
            onClickMethod: (e: React.MouseEvent) => void
        },
        jobExperiences: {
            list: Array<IFilterValue>,
            selectedItems: Record<string, IFilterValue>,
            onClickMethod: (e: React.MouseEvent) => void
        }
    }
}

const MAX_VISIBLE_TAGS = 4;

export const VacanciesFilters: FC<IProps> = ({ filters, tags }) => {
    const cn = useClassnames();
    const [showAllTags, setShowAllTags] = useState(false);
    const { directions, cities, jobTypes, jobExperiences } = filters;

    const separatedTags = useMemo(() => {
        const tagsCopy = [...tags.tags];
        const hiddenCount = Math.max(0, tagsCopy.length - MAX_VISIBLE_TAGS);
        const list = showAllTags ? tagsCopy : tagsCopy.slice(0, MAX_VISIBLE_TAGS);

        return { list, hiddenCount };
    }, [tags.tags, showAllTags]);

    const toggleShowAllTags = useCallback(() => {
        setShowAllTags((prev) => !prev);
    }, []);

    return (
        <div className="directions__wrapper">
            <div>
                <h2 className="directions__header">Вакансии</h2>

                <div className="directions__filters">
                    {Boolean(separatedTags.list.length) && (
                        <div className="directions__filters-item">
                            <h3 className="directions__filters-title">
                                Компетенции
                            </h3>

                            <TagsList
                                tags={{
                                    tags         : separatedTags.list,
                                    selectedItems: tags.selectedItems,
                                    onClickTag   : tags.onClickTag
                                }}
                            />

                            {separatedTags.hiddenCount > 0 && (
                                <button
                                    onClick={toggleShowAllTags}
                                    className="directions__filters-title-button"
                                >
                                    {showAllTags ? 'Свернуть все' : 'Показать все'}
                                </button>
                            )}
                        </div>
                    )}

                    <div className="directions__filters-item">
                        <h3 className="directions__filters-title">Направления</h3>
                        <ul className="directions__points">
                            {directions.list.map((direction, index) => {
                                const isActive = Boolean(directions.selectedItems[direction.id]);
                                return (
                                    <li
                                        key={index}
                                        className={cn('directions__points-item', {
                                            'directions__points-item_active': isActive
                                        })}
                                        data-id={direction.id}
                                        onClick={directions.onClickMethod}
                                    >
                                        <GreenCheckbox
                                            small={true}
                                            isChecked={isActive}
                                        />
                                        {direction.text}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>

                    {cities && (
                        <FilterSection
                            title="Регион"
                            items={cities.list}
                            selectedItems={cities.selectedItems}
                            onClickMethod={cities.onClickMethod}
                            getItemValue={(item) => item.value}
                        />
                    )}

                    {jobTypes && (
                        <FilterSection
                            title="Формат работы"
                            items={jobTypes.list}
                            selectedItems={jobTypes.selectedItems}
                            onClickMethod={jobTypes.onClickMethod}
                        />
                    )}

                    {jobExperiences && (
                        <FilterSection
                            title="Опыт работы"
                            items={jobExperiences.list}
                            selectedItems={jobExperiences.selectedItems}
                            onClickMethod={jobExperiences.onClickMethod}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
export default VacanciesFilters;
