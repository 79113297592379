import { GetServerDataReturn } from 'gatsby';
import React from 'react';

import Footer from '@/components/footer';
import Layout from '@/components/layout';
import VacanciesPage from '@/components/VacanciesPage';
import { GLOBAL_QUERY } from '@/constants';
import { TPageProp } from '@/types';
import { TStrapiEntity } from '@/types/strapi';
import { IGlobalServerRuntimeData } from '@/types/strapi/global';
import {
    IDirection,
    IVacancy
} from '@/types/strapi/vacancies';
import { IVacanciesPage } from '@/types/strapi/vacanciesPage';


type TDirection = IDirection;
type TVacanciesPage = Pick<TStrapiEntity<IVacanciesPage>, 'seo' | 'pageId' | 'isHidden'>;
type TVacancy = Pick<IVacancy, 'id' | 'area' | 'city' | 'jobType' | 'job_experience' | 'tags' | 'title' | 'direction' | 'isSecret' | 'about' | 'isHot'>;

interface IServerRuntimeData extends IGlobalServerRuntimeData {
    directions: Array<TDirection>,
    vacancies: Array<TVacancy>,
    vacanciesPage: TVacanciesPage
}

type TProps = TPageProp<IServerRuntimeData>;

const Vacancies: React.FC<TProps> = ({ location, serverData }) => {
    const { seo } = serverData.vacanciesPage;

    const params = new URLSearchParams(location.search);

    if(!serverData.vacanciesPage || !serverData.vacancies) {
        return null;
    }

    return (
        <div className="vacancies-page">
            <Layout
                seo={{
                    ...seo,
                    shareImage: undefined
                }}
                navPanel={serverData.navPanel}
                global={serverData.global}
                respondForm={serverData.respondForm}
            >
                {/* <h1>TEST</h1>*/}
                <VacanciesPage params={params} serverData={serverData} />
                <Footer className="vacancies__footer" data={serverData.footer} />
            </Layout>
        </div>
    );
};

export default Vacancies;

const query = `
  query getVacancies($locale: String) {
    ${GLOBAL_QUERY}
    vacanciesPage(locale: $locale) {
      seo {
        metaTitle
        metaDescription
      }
      pageId
    }
    vacancies(where: {isSecret: false, isArchived: false}, locale: $locale) {
      id
      isHot
      locale
      created_at
      area {
        text
        value
        id
      }
      city {
        text
        value
        id
      }
      jobType {
        text
        value
        id
      }
      job_experience {
        text
        value
        id
      }
      tags {
        text
        value
        id
      }
      title
      about
      direction {
        header
        id
      }
    }
    directions(locale: $locale) {
      header
      id
    }
  }
`;

export async function getServerData(): Promise<GetServerDataReturn<IServerRuntimeData>> {
    try {
        const variables = JSON.stringify({
            locale: process.env.GATSBY_LOCALE_CODE ?? 'ru'
        });
        const response = await fetch(
            `${process.env.GATSBY_API_URL}/graphql?query=${query}&variables=${variables}`
        );

        if(!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();

        if(!result.data) {
            throw new Error('GraphQL response missing data field');
        }

        const data: { data: IServerRuntimeData } = result;

        // Validate required fields exist
        if(!data.data.vacanciesPage || !data.data.vacancies || !data.data.directions) {
            throw new Error('GraphQL response missing required fields');
        }

        return {
            props: {
                vacanciesPage: data.data.vacanciesPage,
                vacancies    : data.data.vacancies,
                directions   : data.data.directions,
                navPanel     : data.data.navPanel,
                global       : data.data.global,
                respondForm  : data.data.respondForm,
                footer       : data.data.footer
            }
        };
    } catch(error) {
        console.error('Vacancies page getServerData error:', {
            error : error instanceof Error ? error.message : 'Unknown error',
            apiUrl: process.env.GATSBY_API_URL,
            locale: process.env.GATSBY_LOCALE_CODE,
            status: error instanceof Error && 'status' in error ? error.status : undefined,
            query
        });

        return {
            status : 500,
            props  : undefined,
            headers: {
                'Cache-Control': 'no-store'
            }
        };
    }
}
