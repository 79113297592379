import { IFilters } from '../types';

const STORAGE_KEY = 'vacancies_filters';

interface IStoredFilters {
    directions: Record<string, boolean>,
    cities: Record<string, boolean>,
    jobTypes: Record<string, boolean>,
    jobExperiences: Record<string, boolean>,
    tags: Record<string, boolean>,
    searchString?: string
}

export const useFilterPersistence = () => {
    // Загрузка фильтров из localStorage
    const loadFilters = (): IStoredFilters | null => {
        try {
            const saved = localStorage.getItem(STORAGE_KEY);
            return saved ? JSON.parse(saved) : null;
        } catch(e) {
            console.error('Error loading filters:', e);

            return null;
        }
    };

    // Сохранение фильтров в localStorage
    const saveFilters = (filters: IFilters | null | undefined, searchString?: string) => {
        try {
            if (!filters) {
                console.warn('No filters provided to save');
                return;
            }

            // Преобразуем фильтры в более простую структуру для хранения
            const storedFilters: IStoredFilters = {
                directions: {},
                cities: {},
                jobTypes: {},
                jobExperiences: {},
                tags: {},
                searchString
            };

            // Для каждой группы фильтров сохраняем только id выбранных элементов
            Object.entries(filters).forEach(([key, value]) => {
                if (!value || !value.selectedItems) {
                    return;
                }
                if (key in storedFilters) { // Type guard
                    const filterKey = key as keyof Omit<IStoredFilters, 'searchString'>;
                    const selectedIds = Object.keys(value.selectedItems);
                    
                    // Ensure the filter group object exists
                    if (!storedFilters[filterKey]) {
                        storedFilters[filterKey] = {};
                    }
                    
                    // Add selected IDs to the filter group
                    selectedIds.forEach((id) => {
                        (storedFilters[filterKey] as Record<string, boolean>)[id] = true;
                    });
                }
            });

            localStorage.setItem(STORAGE_KEY, JSON.stringify(storedFilters));
        } catch(e) {
            console.error('Error saving filters:', e);
        }
    };

    // Преобразование сохраненных фильтров обратно в формат IFilters
    const convertStoredFiltersToFilters = (storedFilters: IStoredFilters, currentFilters: IFilters): IFilters => {
        const result: IFilters = {
            directions    : { ...currentFilters.directions, selectedItems: {} },
            cities        : { ...currentFilters.cities, selectedItems: {} },
            jobTypes      : { ...currentFilters.jobTypes, selectedItems: {} },
            jobExperiences: { ...currentFilters.jobExperiences, selectedItems: {} },
            tags          : { ...currentFilters.tags, selectedItems: {} }
        };

        Object.entries(storedFilters).forEach(([key, selectedItems]) => {
            if(key !== 'searchString') {
                Object.keys(selectedItems).forEach((id) => {
                    const filterGroup = currentFilters[key as keyof IFilters];
                    if(filterGroup.allItems[id]) {
                        result[key as keyof IFilters].selectedItems[id] = filterGroup.allItems[id];
                    }
                });
            }
        });

        return result;
    };

    return { loadFilters, saveFilters, convertStoredFiltersToFilters };
};
