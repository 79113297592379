import React, { useState } from 'react';

import { VacanciesContent, VacanciesFilterButtons, VacanciesHeader, VacanciesFilters } from './components';
import { useVacanciesFilters, useVacanciesSearch, useVacanciesData } from './hooks';
import { IFilters, IServerRuntimeData, ITag, TFilterType } from './types';

import './index.css';

import { useClassnames } from '@/hooks/use-classnames';

interface IProps {
    serverData: IServerRuntimeData,
    params: URLSearchParams
}

const VacanciesPage: React.FC<IProps> = ({ params, serverData }) => {
    const cn = useClassnames();
    const directionParam = params.get('direction');
    const tagsParam = params.get('tags')?.split(',').map((item) => item) || [];
    const [isMobileFilterVisible, setIsMobileFilterVisible] = useState(false);

    const {
        filters,
        filtersCount,
        toggleFilter,
        activeFilters,
        resetFilters
    } = useVacanciesFilters({
        initialVacancies     : serverData.vacancies,
        initialDirections    : serverData.directions,
        initialDirectionParam: directionParam,
        initialTagsParam     : tagsParam
    });

    const {
        searchString,
        setSearchString,
        debouncedSearchString,
        isSearchStart,
        clearSearch
    } = useVacanciesSearch();

    const handleResetAll = React.useCallback(() => {
        resetFilters();
        clearSearch();
    }, [resetFilters, clearSearch]);

    const { filteredVacancies } = useVacanciesData({
        vacanciesList: serverData.vacancies,
        filters,
        searchString : debouncedSearchString
    });

    const filteredTags = React.useMemo(() => {
        return filteredVacancies.reduce<Array<ITag>>((acc, item) => {
            item.tags?.forEach((tag) => {
                if(
                    !acc.find((tagInAcc) => tagInAcc.id === tag.id)
                  && (Object.keys(filters.directions.selectedItems).length || Object.keys(filters.tags.selectedItems).length)
                ) {
                    acc.push(tag);
                }
            });

            return acc;
        }, []);
    }, [filteredVacancies, filters.directions.selectedItems, filters.tags.selectedItems]);

    const handleTagClick = (e: React.MouseEvent) => {
        const tagId = e.currentTarget.getAttribute('data-id');
        if(tagId) {
            toggleFilter('tags', tagId);
        }
    };

    const handleActiveFilterClick = (e: React.MouseEvent) => {
        const id = e.currentTarget.getAttribute('data-id');
        const type = e.currentTarget.getAttribute('data-filter-type') as TFilterType;

        if(id && type) {
            const filterType = {
                'direction'    : 'directions',
                'city'         : 'cities',
                'jobType'      : 'jobTypes',
                'jobExperience': 'jobExperiences',
                'tags'         : 'tags'
            }[type] as keyof IFilters;

            if(filterType) {
                toggleFilter(filterType, id);
            }
        }
    };

    const filtersProps = React.useMemo(() => ({
        directions: {
            list         : Object.values(filters.directions.allItems),
            selectedItems: filters.directions.selectedItems,
            onClickMethod: (e: React.MouseEvent) => {
                const id = e.currentTarget.getAttribute('data-id');
                if(id) {
                    toggleFilter('directions', id);
                }
            }
        },
        cities: {
            list         : Object.values(filters.cities.allItems),
            selectedItems: filters.cities.selectedItems,
            onClickMethod: (e: React.MouseEvent) => {
                const value = e.currentTarget.getAttribute('data-value');
                if(value) {
                    toggleFilter('cities', value);
                }
            }
        },
        jobTypes: {
            list         : Object.values(filters.jobTypes.allItems),
            selectedItems: filters.jobTypes.selectedItems,
            onClickMethod: (e: React.MouseEvent) => {
                const id = e.currentTarget.getAttribute('data-id');
                if(id) {
                    toggleFilter('jobTypes', id);
                }
            }
        },
        jobExperiences: {
            list         : Object.values(filters.jobExperiences.allItems),
            selectedItems: filters.jobExperiences.selectedItems,
            onClickMethod: (e: React.MouseEvent) => {
                const id = e.currentTarget.getAttribute('data-id');
                if(id) {
                    toggleFilter('jobExperiences', id);
                }
            }
        }
    }), [filters, toggleFilter]);

    const hasActiveFilters = React.useMemo(() => {
        return Object.values(filters).some((filterGroup) => Object.keys(filterGroup.selectedItems).length > 0
        );
    }, [filters]);

    const availableTags = React.useMemo(() => {
        if(!hasActiveFilters) {
            const allTags = new Map();

            serverData.vacancies.forEach((vacancy) => {
                vacancy.tags?.forEach((tag) => {
                    if(!allTags.has(tag.id)) {
                        allTags.set(tag.id, tag);
                    }
                });
            });

            return Array.from(allTags.values());
        }

        const filteredTagsMap = new Map();

        filteredVacancies.forEach((vacancy) => {
            vacancy.tags?.forEach((tag) => {
                if(!filteredTagsMap.has(tag.id)) {
                    filteredTagsMap.set(tag.id, tag);
                }
            });
        });

        return Array.from(filteredTagsMap.values());
    }, [hasActiveFilters, filteredVacancies, serverData.vacancies]);

    return (
        <div className={cn('vacancies-page__wrapper')}>
            {!isMobileFilterVisible ? (
                <React.Fragment>
                    <VacanciesFilters
                        filters={filtersProps}
                        tags={{
                            tags         : availableTags,
                            selectedItems: filters.tags.selectedItems,
                            onClickTag   : handleTagClick
                        }}
                    />

                    <div className={cn('vacancies__wrapper')}>
                        <VacanciesHeader
                            searchString={searchString}
                            setSearchString={setSearchString}
                            availableTags={availableTags}
                            selectedTags={filters.tags.selectedItems}
                            onClickTag={handleActiveFilterClick}
                            clearTags={handleResetAll}
                            activeFilters={activeFilters}
                        />
                        <VacanciesContent
                            isSearchStart={isSearchStart}
                            filteredVacancies={filteredVacancies}
                            searchString={searchString}
                            selectedTags={filters.tags.selectedItems}
                            onClickTag={handleTagClick}
                            activeFilters={activeFilters}
                        />
                    </div>
                    <VacanciesFilterButtons
                        isMobileFilterVisible={isMobileFilterVisible}
                        setIsMobileFilterVisible={setIsMobileFilterVisible}
                        filtersCount={filtersCount}
                        filteredVacancies={filteredVacancies}
                        resetFilters={handleResetAll}
                    />
                </React.Fragment>
            ) : (
                <div className={cn('vacancies__filter-wrapper', {
                    'vacancies__filter-wrapper_visible': isMobileFilterVisible
                })}
                >
                    <p className={cn('vacancies__filter-header')}>Фильтр</p>
                    <VacanciesFilters
                        filters={filtersProps}
                        tags={{
                            tags         : filteredTags,
                            selectedItems: filters.tags.selectedItems,
                            onClickTag   : handleTagClick
                        }}
                    />
                    <VacanciesFilterButtons
                        isMobileFilterVisible={isMobileFilterVisible}
                        setIsMobileFilterVisible={setIsMobileFilterVisible}
                        filtersCount={filtersCount}
                        filteredVacancies={filteredVacancies}
                        resetFilters={handleResetAll}
                    />
                </div>
            )}
        </div>
    );
};

export default VacanciesPage;
